import Input from "~/components/Inputs/Input";
import Tel from "~/components/Inputs/Tel";
import Content from "@referralexchange/rea-ui-react/src/atoms/Content";
import PropTypes from "prop-types";
import { capitalize } from "../../../../utils/TextProcessing";
import { useEffect, useState } from "react";
import { sendTwoFA } from "~/utils/Fetch/Lightning";
import { getLeadUUID, clearLeadUUID } from "~/utils/UUID";
import { countries } from "~/utils/Countries";
import { css, StyleSheet } from "aphrodite";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { genericFunnels } from "~/config/funnel";

import {
  emailRegexString,
  phoneRegexString,
  nameRegexString,
} from "~/utils/Constants/RegexStrings";
import { POSTCONVERT_URL } from "~/utils/FetchingData";

const TwoFA = (props) => {
  const {
    nextScreen,
    twoFAFirstName,
    twoFALastName,
    setTwoFAFirstName,
    setTwoFALastName,
    setTwoFAEmail,
    setTwoFAPhone,
    twoFAPhone,
    twoFAEmail,
    agent,
    twoFA,
    setTwoFADisplayPhone,
    clientPhoneCountry,
    setClientPhoneCountry,
    funnel,
    setConsent,
    consent,
  } = props;

  const router = useRouter();

  const [loadSkip, setLoadSkip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadSkip(true);
    }, 3000);
  }, []);

  const screenKey = "TwoFA";

  /**
   * isValid
   *
   * Validation test for different inputs.
   *
   * @param {string} value
   * @param {string} type
   * @returns {bool}
   */
  const isValid = (value, type) => {
    let valid = false;

    const checkPhoneLength = () => {
      const target =
        countries[clientPhoneCountry]?.phoneFormat.split(".").length - 1;
      return target + (countries[clientPhoneCountry].phone[0].length + 1);
    };

    switch (type) {
      case "name":
        const nameRegex = new RegExp(nameRegexString, "u");
        valid = nameRegex.test(value);
        break;
      case "email":
        const emailRegex = new RegExp(emailRegexString);
        valid = emailRegex.test(value);
        break;
      case "tel":
        const phoneRegex = new RegExp(phoneRegexString);
        valid = phoneRegex.test(value);
        if (valid && clientPhoneCountry && checkPhoneLength() != value.length) {
          valid = false;
        }
        break;
      default:
        valid = false;
        break;
    }
    return valid;
  };

  const header = () => {
    return (
      <div className="MessageAgentForm__screen-heading">
        {genericFunnels.includes(funnel)
          ? "We're preparing to connect you to at least 3 agents. Please verify the following information to get connected sooner:"
          : `We're preparing to connect you to ${capitalize(
              agent.display_name.split(/(\s+)/)[0]
            )}. Please verify the following information to get connected sooner:`}
      </div>
    );
  };

  const next = () => {
    sendTwoFA(getLeadUUID(), twoFAPhone);
    const consent_text_el = document.getElementById("two-fa-consent");
    setConsent({
      ...consent,
      consent_text_original: `By clicking "Text Confirmation Code", I am providing my esign and express written consent to allow ReferralExchange and our affiliated Participating Agents, or parties calling on their behalf, to contact me at the phone number above for marketing purposes, including through the use of calls, SMS/MMS, prerecorded and/or artificial voice messages using an automated dialing system to provide agent info, even if your number is listed on a corporate, state or federal Do-Not-Call list. Consent is not a condition for our service and you can revoke it at any time.`,
      consent_text_shown: consent_text_el
        ? consent_text_el.textContent
        : consent.consent_text_shown,
    });
    nextScreen({ key: screenKey });
  };

  const consentText = (
    <>
      By clicking "Text Confirmation Code", I am providing my esign and express
      written consent to allow ReferralExchange and our affiliated Participating
      Agents, or parties calling on their behalf, to contact me at the phone
      number above for marketing purposes, including through the use of calls,
      SMS/MMS, prerecorded and/or artificial voice messages using an automated
      dialing system to provide agent info, even if your number is listed on a
      corporate, state or federal Do-Not-Call list. Consent is not a condition
      for our service and you can revoke it at any time.
    </>
  );

  return (
    <div className="MessageAgentForm__screen" key="2fa">
      {header()}
      <div className="MessageAgentForm__contact-info">
        {twoFA.name && (
          <>
            <Input
              label={"First name"}
              name={"client-first-name"}
              autoComplete="given-name"
              required={true}
              maxlength="36"
              pattern="[a-zA-Z0-9\- ]*"
              onChange={(value) => {
                setTwoFAFirstName(value);
              }}
              value={twoFAFirstName}
            />
            <Input
              label={"Last name"}
              name={"client-last-name"}
              autoComplete="family-name"
              required={true}
              maxlength="36"
              pattern="[a-zA-Z0-9\- ]*"
              onChange={(value) => {
                setTwoFALastName(value);
              }}
              value={twoFALastName}
            />
          </>
        )}

        {(twoFA.email || twoFA.dupe_email || twoFA.agent_email) && (
          <Input
            label={"Email"}
            type={"email"}
            name={"client-email"}
            autoComplete="email"
            required={true}
            maxlength="255"
            onChange={(value) => {
              setTwoFAEmail(value);
            }}
            value={twoFAEmail}
          />
        )}
        <Tel
          label={"Cell Number"}
          name={"client-phone"}
          autoComplete="tel"
          required={true}
          onChange={(e, value, displayValue) => {
            setTwoFAPhone(value);
            setTwoFADisplayPhone(displayValue);
          }}
          value={twoFAPhone}
          initialCountry={clientPhoneCountry}
          setPhoneCountry={setClientPhoneCountry}
        />
      </div>
      <div className="MessageAgentForm__screen-controls">
        {genericFunnels.includes(funnel) && loadSkip ? (
          <motion.button
            className="--alt"
            type="button"
            initial="pageInitial"
            animate="pageAnimate"
            variants={{
              pageInitial: {
                opacity: 0,
              },
              pageAnimate: {
                opacity: [0, 1],
                transition: {
                  duration: 0.3,
                },
              },
            }}
            onClick={async () => {
              const leadUUID = getLeadUUID();
              clearLeadUUID();
              const referrer = localStorage.getItem("referrer") || "";
              const postConvertURL = await POSTCONVERT_URL({ uuid: leadUUID });
              if (postConvertURL) {
                window.location.href = `${postConvertURL}&experience=tv&referrer=${referrer}`;
              }
            }}
          >
            Skip
          </motion.button>
        ) : (
          <div />
        )}
        <div className="MessageAgentForm__screen-control-text-container">
          <button
            disabled={(() => {
              let disabled = false;

              if (!isValid(twoFAPhone, "tel")) {
                disabled = true;
              }

              if (
                ((twoFA.email || twoFA.dupe_email || twoFA.agent_email) &&
                  twoFAEmail?.length < 4) ||
                !isValid(twoFAEmail, "email")
              ) {
                disabled = true;
              }

              if (
                twoFA.name &&
                (!isValid(twoFAFirstName, "name") ||
                  !isValid(twoFALastName, "name"))
              ) {
                disabled = true;
              }

              return disabled;
            })()}
            onClick={next}
            type="button"
          >
            Text Confirmation Code
          </button>
        </div>
      </div>
      <div id={"two-fa-consent"} className={css(styles.consent)}>
        <Content>
          <small>{consentText}</small>
        </Content>
      </div>
    </div>
  );
};

TwoFA.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  screensSeen: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  twoFA: PropTypes.object.isRequired,
  setConsent: PropTypes.func.isRequired,
  consent: PropTypes.object.isRequired,
};

TwoFA.defaultProps = {
  nextScreen: () => {},
  screensSeen: new Set(),
  agent: {},
  setConsent: () => {},
  consent: {},
};

const styles = StyleSheet.create({
  consent: {
    marginTop: 16,
  },
  skip: {
    textDecoration: "underline",
  },
});

export default TwoFA;
